import React from 'react'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'

import SEO from '../components/seo'
import CareerPostItem from '../components/career-post-item'

import Breadcrumbs from '../components/breadcrumbs'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`

const Heading = styled.div`
  background: #fff;

  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const CareersPage = (props) => {
  const data = useStaticQuery(graphql`
    query GetAllCareers {
      allContentfulCareers {
        edges {
          node {
            name
            slug
            excerpt {
              excerpt
            }
            image {
              gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout pageProps={props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                {
                  to: props.location.pathname,
                  label: `Careers`,
                },
              ]}
            />
          </nav>
        </div>
        <SEO
          title={
            'US Quality Construction Careers & Jobs | Missouri'
          }
          description={"Explore exciting career opportunities at US Quality Construction in Missouri. See our job openings and join our expert home renovation team. Build a rewarding career with us today!"}
        />
        <Heading style={{ paddingTop: '1em' }}>
          <h1>US Quality Construction Careers</h1>
          <p className='container'>To maintain our high standards of quality service, we prioritize building a team of reliable and dedicated professionals. <br />Each US Quality Construction employee embodies our core values and is committed to ensuring customer satisfaction. If you are interested in joining our team, please review our available positions below.</p>
        </Heading>
        <BlogPostsLayoutContainer className="container self-stretch">
          {data.allContentfulCareers.edges.map((item, i) => (
            <CareerPostItem {...item.node} key={item.node.id} />
          ))}
        </BlogPostsLayoutContainer>
      </Layout>
    </>
  )
}

export default CareersPage