import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import React from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'

import daysFromToday from '../helpers/daysFromToday'

import Button from '../components/button'

import { ItemContainer, PublishDate, Heading, Excerpt } from './blog-post-item'

const CareerPostItem = ({
  name,
  slug,
  seoH1Title,
  image,
  page = 'careers',
  className,
  excerpt

}) => {
  return (
    <ItemContainer className={className}>
      <div
        className={'shadow-md border-radius bg-white'}
        style={{ backgroundColor: 'white' }}
      >
        <GatsbyImage
          image={getImage(image)}
          style={{ height: '220px' }}
          alt={name}
        />
       <div
          className={'flex flex-row justify-around items-stretch text-center'}
        >
          <div
            className={
              'innerpadding flex flex-col justify-around items-stretch'
            }
          >
        <div className="self-stretch">
              <Heading>{name}</Heading>

              {excerpt ? <Excerpt>{excerpt.excerpt}</Excerpt> : null}
              
            </div>

            <Button
              to={`/${page}/` + slug + `/`}
              color={'secondary'}
              
            >
              More Details
              →
            </Button>
        </div>
        </div>
      </div>
    </ItemContainer>
  )
}

export default CareerPostItem